import './App.css';
import { Route, Routes } from 'react-router-dom';
import PrivateRoutes from './utils/PrivateRouter';
import Login from './container/Login';
import Dashboard from './container/Dashboard';
import Completed from './container/Completed';
import { ToastContainer } from 'react-toastify';
import Home from './container/Home';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/' element={<PrivateRoutes />}>
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/dashboard/completed' element={<Completed/>}/>
          <Route path='/home' element={<Home />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
