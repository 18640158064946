const MESSAGE = {
    LOGIN: "Login",
    USER_NAME: "User Name",
    PASSWORD: "Password",
    PLEASE_ENTER_THE_USERNAME: "Please enter the user name",
    PLEASE_ENTER_VALID_USERNAME: "Please enter valid user name",
    PLEASE_ENTER_THE_PASSWORD: "Please enter the password",
    NUTRITION: "Nutrition",
    INGREDIENT: "Ingredient",
    ALLERGENS: "Allergens",
    CONTAINS: "Contains",
    UPDATE: "Update",
    SUBMIT: "Submit",
    PREVIOUS: "Previous",
    NEXT: "Next",
    DASHBOARD:"Dashboard",
    ALL:"All",
    VENDOR:"ALL VENDOR",
    ENVIRONMENT:"ALL ENVIRONMENT",
    LEVEL_1:"Level 1",
    LEVEL_2:"Level 2",
    LEVEL_3:"Level 3",
    LEVEL_4:"Level 4",
    COMPLETED:"Completed",
    INCOMPLETED:"Incomplete",
    SKU:"SKU:",
    VENDOR_ID:"Vendor Id:",
    VENDOR_NAME:"Vendor Name:",
    PRODUCT_NAME:"Product Name:",
    PRODUCT_BOX_ID:"Product Box Id:",
    VIEW:"View",
    NO_DATA_FOUND:"No Data Found"
}

export const mock="[['Nutrition Facts'], ['About 14 servings per container'], ['Serving size  1/4 cup (45g)'], ['Amount per serving'], ['Calories', '170'], ['%DV*'], ['Total Fat  1.5g', '2%', '0%'], ['V'], ['Saturated Fat Og'], ['Trans Fat Og'], ['Sodium Omg', '0%'], ['Total Carbohydrate 34g12%'], ['Dietary Fiber  1g', '4%'], ['Protein  4g'], ['Iron  1mg', '6%'], ['Not a sinificant source of cholesterol, totalsugars'], ['added sugarvitamin D.calcum and poassium'], ['*%DV=%Daily Value']]"

export default MESSAGE