import React from "react"

const Home=()=>{
    return(
        <React.Fragment>
            Home
        </React.Fragment>
    )
}

export default Home