import React, { useEffect, useState } from "react"
import ProgressBar from "@ramonak/react-progress-bar";
import { PaginationSession } from "../component/Pagination";
import { useDispatch, useSelector } from "react-redux";
import { Actions } from "../redux/action";
import ProductPopup from "../component/ProductPopup";
import { toast } from "react-toastify";
import { DataNotFound } from "../component/DataNotFound";
import Loader from "../component/Loader";
import { getEnvironmentList, getProductListData, getTotalCount, getVendorList } from "../redux/reducer/productReducer";
import MESSAGE from "../constant/String";
import CalenderFilter from "../asset/img/calender_filter.png";
import DatePickerModel from "../utils/DatePicker";
import moment from "moment/moment";
import { useNavigate } from "react-router-dom";
// import ShowDateMoment from '.././utils/Utils'

const Dashboard = ({ isCompleted }) => {
    const [pageNumber, setPageNumber] = useState(1);
    const [productDetails, setProductDetails] = useState('');
    const [showPopup, setShowPopup] = useState(false)
    const [submitStatus, setSubmitStatus] = useState(isCompleted ? 1 : 0)
    const [levelStatus, setLevelStatus] = useState('')
    const [vendorId, setVendorId] = useState('')
    const [environmentId, setEnvironmentId] = useState('')
    const [search, setSearch] = useState('')
    const [showCalenderPopup, setShowCalenderPopup] = useState(false)
    const [isLoading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const vendorList = useSelector(getVendorList);
    const environmentList = useSelector(getEnvironmentList);
    const productList = useSelector(getProductListData);
    const tokenSessionData = sessionStorage.getItem('token');
    const totalCount = useSelector(getTotalCount);
    const dispatch = useDispatch();
    const navigate = useNavigate();


    //Callback for the ProdcutList API
    const productListCallback = (response) => {
        setLoading(false)
        if (response?.status_code !== 0) {
            toast.error(response?.response_message)
        }
    }

    const productListApi = (search, level, submit, page, vendorId, fromDate, toDate, environmentdata) => {
        let params = {
            keyword: search,
            level: level,
            is_completed: submit,
            pagenum: page,
            limit: 10,
            vendor_id: vendorId,
            environment: environmentdata
        }
        if (fromDate && toDate) {
            params['start_date'] = moment(fromDate).format('YYYY-MM-DD')
            params['end_date'] = moment(toDate).format('YYYY-MM-DD')
        }
        setLoading(true)
        dispatch(Actions.productList(params, productListCallback))
        dispatch(Actions.vendorList("", () => { }))
    }
    useEffect(() => {
        const token = JSON.parse(tokenSessionData);
        if (token) {
            navigate('/dashboard')
        } else {
            navigate('/')
        }
    }, [])

    useEffect(() => {
        productListApi(search, levelStatus, submitStatus, pageNumber,vendorId,startDate,endDate,environmentId)
    }, [pageNumber, submitStatus])

    //Function to trigger the search api with serach input
    const onchangeSearch = (event) => {
        let serach = event.target.value
        setSearch(serach.trim())
        setPageNumber(1)
        productListApi(serach.trim(), levelStatus, submitStatus, pageNumber,vendorId,startDate,endDate,environmentId)
    }

    //Function to trigger the search api with level select dropdown
    const handleLevel = (event) => {
        setLevelStatus(event.target.value)
        productListApi(search, event.target.value, submitStatus, pageNumber,vendorId,startDate,endDate,environmentId)
    }

    const handleVendorId = (event) => {
        setVendorId(event.target.value)
        getEnvironmentData(event.target.value)
        setEnvironmentId('')
        productListApi(search, levelStatus, submitStatus, pageNumber, event.target.value,startDate,endDate,'')

    }

    //handle the environment dropdown and set the data
    const handleEnvironmentId = (event) => {
        setEnvironmentId(event.target.value)
        setPageNumber(1)
        productListApi(search, levelStatus, submitStatus, 1, vendorId, startDate, endDate, event.target.value)
    }

    //function to get the environment list api
    const getEnvironmentData = (vendorId) => {
        const callback = (res) => {
        }
        let params = {
            vendor_id: vendorId
        }
        dispatch(Actions.environmentList(vendorId !== "" ? params : {}, callback))
    }

    //Function to trigger the search api with complete select dropdown
    const handleComplete = (event) => {
        setSubmitStatus(event.target.value)
        productListApi(search, levelStatus, event.target.value, pageNumber,vendorId,startDate,endDate,environmentId)
    }

    //This function used for pagination its affect pageNumber state
    const onChangeHandle = (page) => {
        setPageNumber(page)
    }

    //handle the product view affect the showpopup and product details state
    const handleProductView = (obj) => {
        setProductDetails(obj)
        setShowPopup(true)
    }

    const onDateChanged = (date, flag) => {
        if (flag === 1) {
            setStartDate(date)
        } else {
            setEndDate(date)
        }
    }

    const handleSuccess = () => {
        productListApi("", levelStatus, submitStatus, pageNumber, vendorId, startDate, endDate,environmentId)
        setShowCalenderPopup(false)
    }

    const clearFilter = () => {
        setStartDate(null);
        setEndDate(null);
    }
    const handleProductDetails = () => {
        productListApi(search, levelStatus, submitStatus, pageNumber,vendorId,startDate,endDate,environmentId)
    }
    return (
        <React.Fragment>
            {
                isLoading && <Loader />
            }
            <div className="fw-bold fs-4 text-start mx-2">
                {MESSAGE?.DASHBOARD}
            </div>
            <div className="row my-3 mx-2">
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                    <div className="pt-lg-0 pt-3">
                        <select className="form-select pe-5 fs-14" value={vendorId} onChange={handleVendorId}>
                            <option selected value="">{MESSAGE?.VENDOR}</option>
                            {
                                vendorList?.map((obj) => (
                                    <option value={obj.user__id}>{obj.user__first_name + " " + obj.user__last_name}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                    <div className="pt-lg-0 pt-3">
                        <select className="form-select pe-5 fs-14" disabled={!vendorId} value={environmentId} onChange={handleEnvironmentId}>
                            <option selected value="">{MESSAGE?.ENVIRONMENT}</option>
                            {
                                environmentList?.map((obj) => (
                                    <option value={obj.id}>{obj.environment_code}</option>
                                ))
                            }
                        </select>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-2 col-xxl-2">
                    <div className="pt-lg-0 pt-3">
                        <select className="form-select pe-5 fs-14" value={levelStatus} onChange={handleLevel}>
                            <option selected value="">{MESSAGE?.ALL}</option>
                            <option value={4}>{MESSAGE?.LEVEL_4}</option>
                            <option value={3}>{MESSAGE?.LEVEL_3}</option>
                            <option value={2}>{MESSAGE?.LEVEL_2}</option>
                            <option value={1}>{MESSAGE?.LEVEL_1}</option>
                        </select>
                    </div>
                </div>
                <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-3 col-xxl-3">
                    <div className="input-group serachInputDiv border_input">
                        <input type="search" className="form-control border-1" placeholder=" Search by product name" onChange={onchangeSearch} />
                        <span className="input-group-text span_bg border-0"><i class="fa-solid fa-magnifying-glass"></i></span>
                    </div>
                </div>
                <div className="col-4 col-sm-12 col-md-12 col-lg-4 col-xl-1 col-xxl-2">
                    <div className="pt-lg-0 pt-3" onClick={() => setShowCalenderPopup((prevState) => {
                        return !prevState;
                    })}>
                        <img src={CalenderFilter} alt="Yes" className="icon_size" />
                    </div>
                </div>
                {/* <div className="col-12 col-sm-12 col-md-12 col-lg-4 col-xl-4 col-xxl-4">
                    <div className="pt-lg-0 pt-3">
                        <select className="form-select pe-5 fs-14" value={submitStatus} onChange={handleComplete}>
                            <option selected value="">{MESSAGE?.ALL}</option>
                            <option value={1}>{MESSAGE?.COMPLETED}</option>
                            <option value={0}>{MESSAGE?.INCOMPLETED}</option>
                        </select>
                    </div>
                </div> */}
            </div>
            <div className="mainContiner">
                {
                    productList?.length > 0 ?
                        productList?.map((item, index) => {
                            return (
                                <div className="container-fluid py-2 slide_in" key={index}>
                                    <div className="card card_shadow">
                                        <div className="row">
                                            <div className="col-sm-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 d-flex align-items-center">
                                                <div className="row my-3 w-100">
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 lh-lg text-end fw-bold">{MESSAGE?.SKU}</div>
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 lh-lg text-start">{item?.sku}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-5 col-lg-5 col-xl-5 col-xxl-5 py-3 productDetails">
                                                {/* <div className="row">
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 lh-lg text-start fw-bold">{MESSAGE?.VENDOR_ID}</div>
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 lh-lg text-start">{item?.vendor}</div>
                                                </div> */}
                                                <div className="row">
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 lh-lg text-start fw-bold">{MESSAGE?.VENDOR_NAME}</div>
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 lh-lg text-start">{item?.user_info?.user__first_name + " " + item?.user_info?.user__last_name}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 lh-lg text-start fw-bold">{MESSAGE?.PRODUCT_NAME}</div>
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 lh-lg text-start">{item?.product_name}</div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-4 col-xxl-4 lh-lg text-start fw-bold">{MESSAGE?.PRODUCT_BOX_ID}</div>
                                                    <div className="col-6 col-sm-6 col-md-6 col-lg-6 col-xl-8 col-xxl-8 lh-lg text-start">{item?.pb_id}</div>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2 py-1">
                                                <div className="row levelAlertContainer py-3">
                                                    <div className={`levelAlert ${item?.ocr_level === 1 ? "levelOne" : item?.ocr_level === 2 ? "levelTwo" : item?.ocr_level === 3 ? "levelThree" : "levelFour"}`}>
                                                        {`Level ${item?.ocr_level}`}
                                                    </div>
                                                </div>
                                                <div className="row submitStatusContainer">
                                                    <ProgressBar className="wrapper" completed={item?.is_submitted ? 100 : 0} bgColor={"#00D100"} height={10} isLabelVisible={false} />
                                                    <p className="submitStatus"><i class="fa-solid fa-circle-dot"></i>{`${item?.is_submitted ? "Completed" : "InComplete"}`}</p>
                                                </div>
                                            </div>
                                            <div className="col-sm-6 col-md-2 col-lg-2 col-xl-2 col-xxl-2 text-center viewButton">
                                                <button className="btn btn-primary" onClick={() => handleProductView(item)}>{MESSAGE?.VIEW}</button>
                                            </div>
                                        </div>
                                        {/* <div className="row">
                                            <div className="col font12 fw-bold text-end">
                                               
                                                <h4 className="font12 fw-bold">{ShowDateMoment(item?.pb_id)}</h4>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            )
                        }) : <DataNotFound Error={MESSAGE?.NO_DATA_FOUND} />
                }
            </div>
            {
                showPopup && <ProductPopup productDetails={productDetails} close={() => setShowPopup(false)} handleProductDetails={handleProductDetails} />
            }
            {
                productList?.length > 0 && totalCount > 1 && <PaginationSession onChangeHandle={onChangeHandle} currentPage={pageNumber} sessionCount={totalCount} />
            }
            {
                showCalenderPopup && <DatePickerModel startDate={startDate} endDate={endDate} proceed={"Proceed"} cancel={"Cancel"} onDateChange={onDateChanged} onFailure={() => setShowCalenderPopup(false)} onSuccess={() => handleSuccess()} clearFilter={clearFilter} />
            }
        </React.Fragment>
    )
}

export default Dashboard