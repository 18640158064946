import { doAction, doResponseAction } from ".";

const LOGIN_USER = 'LOGIN_USER';
const LOGIN_USER_SUCCESS = 'LOGIN_USER_SUCCESS';
const LOGIN_USER_FAILURE = 'LOGIN_USER_FAILURE';

const LOGOUT = 'LOGOUT';
const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export {
    LOGOUT,
    LOGOUT_FAILURE,
    LOGOUT_SUCCESS,

    LOGIN_USER,
    LOGIN_USER_FAILURE,
    LOGIN_USER_SUCCESS,
}
// LOGOUT
export const logOut = (params, callback) => doAction(LOGOUT, params, callback);
export const logOutSuccess = (data) => doResponseAction(LOGOUT_SUCCESS, data);
export const logOutFailure = (data) => doResponseAction(LOGOUT_SUCCESS, data);

// LOGIN
export const loginUser = (params, callback) => doAction(LOGIN_USER, params, callback);
export const loginUserSuccess = (data) => doResponseAction(LOGIN_USER_SUCCESS, data);
export const loginUserFailure = (data) => doResponseAction(LOGIN_USER_FAILURE, data);