const END_POINTS = {
    API_BASE_URL: "https://ocr.proglint.com",
    // API_BASE_URL: "http://192.168.1.83:8900",
    LOGIN: "/login",
    PRODUCT_LIST_API: "/processocr/list",
    PRODUCT_SEARCH_API: "",
    PRODUCT_UPDATE_API: "/image/ocr/update",
    PRODUCT_SUBMIT_API: "/image/ocr/submit",
    VENDOR_LIST_API: "/vendor/list",
    ENVIRONMENT_LIST_API: "/environment/list",
}

export default END_POINTS