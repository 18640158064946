import Header from "./Header"
import Footer from "./Footer"
import Sidebar from "./Sidebar"
import { Outlet } from "react-router-dom"
const Layout = () => {
    return (
        <div class="container-fluid">
            <div class="row flex-nowrap">
                <Sidebar />
                <div class="col py-3 mx-2">
                    <Outlet />
                </div>
            </div>
        </div>
    )
}

export default Layout