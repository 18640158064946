import { takeLatest, all, put } from 'redux-saga/effects';
import { Actions } from '../action/index';
import END_POINTS from '../../service/EndPoint';
import { ENVIRONMENT_LIST, PRODUCT_LIST, PRODUCT_SEARCH, PRODUCT_SUBMIT, PRODUCT_UPDATE, VENDOR_LIST } from '../action/productAction';
import { doGet, doPost } from '../../service';

//Product List saga to trigger the get axios
export function* productListSaga(param) {
  const { params, callback } = param
  try {
    const response = yield doGet(END_POINTS.PRODUCT_LIST_API, params)
    yield put(Actions.productListSuccess(response))
    callback(response)

  } catch (error) {
    callback({ error })
    yield put(Actions.productListFailure(error))
  }
}

//Product Search saga to trigger the get axios
export function* productSearchSaga(param) {
  const { params, callback } = param

  try {
    const response = yield doGet(END_POINTS.PRODUCT_SEARCH_API, params)
    yield put(Actions.productSearchSuccess(response?.response))
    callback(response)

  } catch (error) {
    callback({ error })
    yield put(Actions.productSearchFailure(error))
  }
}

//Product update saga to trigger the post axios
export function* productUpdateSaga(param) {
  const { params, callback } = param

  try {
    const response = yield doPost(END_POINTS.PRODUCT_UPDATE_API, params)
    yield put(Actions.productUpdateSuccess(response?.response))
    callback(response)

  } catch (error) {
    yield put(Actions.productUpdateFailure(error))
    callback({ error })
  }
}

//Product submit saga to trigger the post axios
export function* productSubmitSaga(param) {
  const { params, callback } = param

  try {
    const response = yield doPost(END_POINTS.PRODUCT_SUBMIT_API, params)
    yield put(Actions.productSubmitSuccess(response?.response))
    callback(response)
  } catch (error) {
    callback && callback(error)
    yield put(Actions.productSubmitFailure(error))
  }
}

export function* vendorListSaga(param) {
  const { params, callback } = param
  try {
    const response = yield doGet(END_POINTS.VENDOR_LIST_API, params)
    yield put(Actions.vendorListSuccess(response?.response))
    callback(response)
  } catch (error) {
    callback && callback(error)
    yield put(Actions.vendorListFailure(error))
  }
}

export function* environmentListSaga(param) {
  const { params, callback } = param
  try {
    const response = yield doGet(END_POINTS.ENVIRONMENT_LIST_API, params)
    yield put(Actions.environmentListSuccess(response?.response))
    callback(response)
  } catch (error) {
    callback && callback(error)
    yield put(Actions.environmentListFailure(error))
  }
}

export default function* productWatcher() {
  yield all([
    takeLatest(PRODUCT_LIST, productListSaga),
    takeLatest(PRODUCT_SEARCH, productSearchSaga),
    takeLatest(PRODUCT_UPDATE, productUpdateSaga),
    takeLatest(PRODUCT_SUBMIT, productSubmitSaga),
    takeLatest(VENDOR_LIST, vendorListSaga),
    takeLatest(ENVIRONMENT_LIST, environmentListSaga),
  ]);
}
