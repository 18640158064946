import { Actions } from '../action';

const initialState = {
    isLoading: false,
    productList: {},
    totalCount: '',
    productUpdate: {},
    productSubmit: {},
    error: false,
    vendorList: [],
    environmentList:[]
}
export const productReducer = (state = initialState, action) => {
    switch (action.type) {
        //PRODUCT_LIST
        case Actions.PRODUCT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PRODUCT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                productList: action?.data?.response,
                totalCount: action?.data?.total_count,
                error: false
            };
        }
        case Actions.PRODUCT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                productList: {},
                error: action.error,
            };
        }

        //PRODUCT_SEARCH
        case Actions.PRODUCT_SEARCH: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PRODUCT_SEARCH_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                productList: action?.data,
                error: false
            };
        }
        case Actions.PRODUCT_SEARCH_FAILURE: {
            return {
                ...state,
                isLoading: false,
                productList: {},
                error: action.error,
            };
        }

        //PRODUCT_UPDATE
        case Actions.PRODUCT_UPDATE: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PRODUCT_UPDATE_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                productUpdate: action?.data,
                error: false
            };
        }
        case Actions.PRODUCT_UPDATE_FAILURE: {
            return {
                ...state,
                isLoading: false,
                productUpdate: {},
                error: action.error,
            };
        }

        //PRODUCT_SUBMIT
        case Actions.PRODUCT_SUBMIT: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.PRODUCT_SUBMIT_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                productSubmit: action?.data,
                error: false
            };
        }
        case Actions.PRODUCT_SUBMIT_FAILURE: {
            return {
                ...state,
                isLoading: false,
                productSubmit: {},
                error: action.error,
            };
        }

        //VENDOR
        case Actions.VENDOR_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.VENDOR_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                vendorList: action?.data,
                error: false
            };
        }
        case Actions.VENDOR_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                vendorList: [],
                error: action.error,
            };
        }

         //VENDOR
         case Actions.ENVIRONMENT_LIST: {
            return {
                ...state,
                isLoading: true,
            };
        }
        case Actions.ENVIRONMENT_LIST_SUCCESS: {
            return {
                ...state,
                isLoading: false,
                environmentList: action?.data,
                error: false
            };
        }
        case Actions.ENVIRONMENT_LIST_FAILURE: {
            return {
                ...state,
                isLoading: false,
                environmentList: [],
                error: action.error,
            };
        }
        default:
            return state;
    }
};

export const getProductListData = (state) => state?.PRODUCT?.productList;
export const getTotalCount = (state) => state?.PRODUCT?.totalCount;
export const getVendorList = (state) => state?.PRODUCT?.vendorList;
export const getEnvironmentList = (state) => state?.PRODUCT?.environmentList;
