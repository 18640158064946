import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Actions } from "../redux/action"
import { validateName, validateminLength } from "../utils/Validation"
import MESSAGE from "../constant/String"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import Loader from "../component/Loader"
import { getLoginData } from "../redux/reducer/loginReducer"

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tokenSessionData = sessionStorage.getItem('token');
    const [formDetails, setFormDetails] = useState({ name: "", password: "" });
    const [passwordType, setPasswordType] = useState("password");
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState({ password: "", name: "" });

    useEffect(() => {
        const token = JSON.parse(tokenSessionData);
        if (token) {
            navigate('/dashboard')
        }else{
            navigate('/')
        }
    }, [tokenSessionData])

    //This function is used for password toggle affect the passwordType state
    const togglePassword = (event) => {
        event.preventDefault();
        if (passwordType === "password") {
            setPasswordType("text")
            return;
        }
        setPasswordType("password")
    }

    //This function affect the error state and formDetails state for user input
    const handleChange = (event) => {
        if (event.target.value === "") {
            setError({ ...error, name: MESSAGE.PLEASE_ENTER_THE_USERNAME })
        } else if (!validateminLength(event.target.value)) {
            setError({ ...error, name: MESSAGE.PLEASE_ENTER_VALID_USERNAME })
        } else {
            setError({ ...error, name: '' })
        }
        setFormDetails({ ...formDetails, name: event.target.value })
    }

    //This function affect the error state and formDetails state for passoword input
    const handlePasswordChange = (event) => {
        if (event.target.value === "") {
            setError({ ...error, password: MESSAGE.PLEASE_ENTER_THE_PASSWORD })
        } else {
            setError({ ...error, password: '' })
        }
        setFormDetails({ ...formDetails, password: event.target.value })
    }

    //This function affect the error,formDetails,isLoading states used for trigger login API
    const OnSubmit = (event) => {
        event.preventDefault()
        const { name, password } = formDetails
        const callback = (response) => {
            setLoading(false)
            if (response?.status_code !== 0) {
                // toast.error(response?.message)
                toast.error("Invalid credentials")
            } else {
                navigate("/dashboard")
            }
        }
        if (name === "") {
            setError({ ...error, name: MESSAGE.PLEASE_ENTER_THE_USERNAME })
        } else if (!validateminLength(name)) {
            setError({ ...error, name: MESSAGE.PLEASE_ENTER_VALID_USERNAME })
        } else if (formDetails?.password === "") {
            setError({ ...error, password: MESSAGE.PLEASE_ENTER_THE_PASSWORD })
        } else {
            setLoading(true)
            dispatch(Actions.loginUser({ username: name, password: password }, callback))
        }
    }

    return (
        <React.Fragment>
            {
                isLoading && <Loader />
            }
            <section className="h-100">
                <div className="container h-100">
                    <div className="row justify-content-sm-center h-100">
                        <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9x">
                            <div className="text-center my-5">
                                <img src={require('../asset/img/proglintLogo.png')} alt="logo" width="120" />
                            </div>
                            <div className="card shadow-lg">
                                <div className="card-body p-5">
                                    <h1 className="fs-4 card-title fw-bold mb-4">{MESSAGE.LOGIN}</h1>
                                    <div className="mb-3 text-start">
                                        <label className="mb-2 text-muted" for="user">{MESSAGE.USER_NAME}</label>
                                        <input id="user" type="user" className="form-control" name="user" onChange={handleChange} required autofocus />
                                        {error?.name && <p className="text-danger">{error?.name}</p>}
                                    </div>
                                    <div className="mb-3 text-start">
                                        <div className="mb-2 w-100">
                                            <label className="text-muted" for="password">{MESSAGE.PASSWORD}</label>
                                        </div>
                                        <div className="input-group serachInputDiv border_input">
                                            <input id="password" type={passwordType} className="form-control" name="password" onChange={handlePasswordChange} required />
                                            <span className="input-group-text span_bg border-0" onClick={togglePassword}>{passwordType === "password" ? <i class="fa-solid fa-eye-slash"></i> : <i class="fa-solid fa-eye"></i>}</span>
                                        </div>

                                        {error?.password && <p className="text-danger">{error?.password}</p>}
                                    </div>
                                    <div className="align-items-center">
                                        <button type="submit" className="btn btn-primary ms-auto" onClick={OnSubmit}>
                                            {MESSAGE.LOGIN}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default Login