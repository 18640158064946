import React from "react"
import { Link, useLocation, useNavigate } from "react-router-dom"
import { Actions } from "../../redux/action";
import { useDispatch } from "react-redux";

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleLogout = () => {
        sessionStorage.clear();
        const callback=(res)=>{
            navigate("/")
        }
        dispatch(Actions.logOut('',callback))
    }
    return (
        <React.Fragment>
            {/* <div className="col-auto col-md-3 col-xl-2 shadow">
                <div className="d-flex flex-column align-items-center align-items-sm-start  text-white min-vh-100">
                    <div className="my-3 w-100">
                        <img className="d-block mx-auto" src={require('../../asset/img/proglintLogo.png')} alt="logo" width="100" />
                    </div>
                    <ul className="nav nav-pills flex-column mb-sm-auto mb-0 w-100 align-items-center text-start align-items-sm-start" id="menu">
                        <li className="nav-item w-100 py-2">
                            <NavLink className={`${location.pathname === "/dashboard" ? "nav-link" : ""} text-decoration-none w-100 text-dark`} to={"/dashboard"}><i className="fa-solid fa-gauge fa-xl"></i><span className="ms-2 d-none d-sm-inline">Dashboard</span></NavLink>
                        </li>
                        <li className="nav-item w-100 py-2">
                            <NavLink className={`${location.pathname === "/dashboard/completed" ? "nav-link" : ""} text-decoration-none w-100 text-dark`} onClick={(e) => {
                                e.preventDefault();
                                navigate("/dashboard/completed", { state: nanoid() });
                            }}><i className="fa-solid fa-check fa-xl"></i><span className="ms-2 d-none d-sm-inline">Completed</span></NavLink>
                        </li>
                    </ul>
                    <div className="mx-2 pr-4 pb-4" onClick={clearLoaclStograge}>
                        <NavLink className=" nav-link text-decoration-none w-100 text-dark" to={"/"}><i className="fa-solid fa-right-from-bracket fa-xl"></i><span className="ms-2 d-none d-sm-inline">Sign Out</span></NavLink>
                    </div>
                </div>
            </div> */}
            <div className="col-auto col-md-3 col-xl-2">
                <div className="d-flex flex-column align-items-center align-items-sm-start text-white min-vh-100"></div>
                <div className="nav-left-sidebar sidebar-dark">
                    <div className="menu-list">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <span className="d-xl-none d-lg-none">Dashboard</span>
                            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                                <span className="navbar-toggler-icon"></span>
                            </button>
                            <div className="collapse navbar-collapse" id="navbarNav">
                                <ul className="navbar-nav flex-column">
                                    <li class="nav-divider">
                                        <div className="my-3 w-100">
                                            <img className="d-block mx-auto" src={require('../../asset/img/proglintLogo.png')} alt="logo" width="100" />
                                        </div>
                                    </li>
                                    <li>
                                        <Link to={"/dashboard"} style={{textDecoration:"none"}}><span className={`${location.pathname === "/dashboard" ? "nav-link active" : "nav-link"}`} data-toggle="collapse" aria-expanded="false" data-target="#submenu-1" aria-controls="submenu-1"><i className="fa fa-dashboard"></i>Dashboard</span></Link>
                                    </li>
                                    <li>
                                        <Link to={"/dashboard/completed"} style={{textDecoration:"none"}}><span className={`${location.pathname === "/dashboard/completed" ? "nav-link active" : "nav-link"}`} data-toggle="collapse" aria-expanded="false" data-target="#submenu-2" aria-controls="submenu-2"><i className="fa-solid fa-check fa-xl"></i>Completed</span></Link>
                                    </li>
                                </ul>
                            </div>
                        </nav>
                    </div>
                    <div className="mt-4 py-2 logoutStyle text-left" onClick={()=>handleLogout()}>
                        <Link to={"/dashboard"} style={{textDecoration:"none"}}><span className="logout-text"><i className="fa-solid fa-sign-out fa-xl mx-2"></i>Logout</span></Link>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </React.Fragment>
    )
}

export default Sidebar