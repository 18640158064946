import { doAction, doResponseAction } from ".";

const PRODUCT_LIST = 'PRODUCT_LIST';
const PRODUCT_LIST_SUCCESS = 'PRODUCT_LIST_SUCCESS';
const PRODUCT_LIST_FAILURE = 'PRODUCT_LIST_FAILURE';

const PRODUCT_SEARCH = 'PRODUCT_SEARCH';
const PRODUCT_SEARCH_SUCCESS = 'PRODUCT_SEARCH_SUCCESS';
const PRODUCT_SEARCH_FAILURE = 'PRODUCT_SEARCH_FAILURE';

const PRODUCT_UPDATE = 'PRODUCT_UPDATE';
const PRODUCT_UPDATE_SUCCESS = 'PRODUCT_UPDATE_SUCCESS';
const PRODUCT_UPDATE_FAILURE = 'PRODUCT_UPDATE_FAILURE';

const PRODUCT_SUBMIT = 'PRODUCT_SUBMIT';
const PRODUCT_SUBMIT_SUCCESS = 'PRODUCT_SUBMIT_SUCCESS';
const PRODUCT_SUBMIT_FAILURE = 'PRODUCT_SUBMIT_FAILURE';

const VENDOR_LIST = 'VENDOR_LIST';
const VENDOR_LIST_SUCCESS = 'VENDOR_LIST_SUCCESS';
const VENDOR_LIST_FAILURE = 'VENDOR_LIST_FAILURE';

const ENVIRONMENT_LIST = 'ENVIRONMENT_LIST';
const ENVIRONMENT_LIST_SUCCESS = 'ENVIRONMENT_LIST_SUCCESS';
const ENVIRONMENT_LIST_FAILURE = 'ENVIRONMENT_LIST_FAILURE';

export {
    PRODUCT_SUBMIT,
    PRODUCT_SUBMIT_FAILURE,
    PRODUCT_SUBMIT_SUCCESS,

    PRODUCT_UPDATE,
    PRODUCT_UPDATE_FAILURE,
    PRODUCT_UPDATE_SUCCESS,

    PRODUCT_SEARCH,
    PRODUCT_SEARCH_FAILURE,
    PRODUCT_SEARCH_SUCCESS,

    PRODUCT_LIST,
    PRODUCT_LIST_FAILURE,
    PRODUCT_LIST_SUCCESS,

    VENDOR_LIST,
    VENDOR_LIST_FAILURE,
    VENDOR_LIST_SUCCESS,

    ENVIRONMENT_LIST,
    ENVIRONMENT_LIST_FAILURE,
    ENVIRONMENT_LIST_SUCCESS,
}

// PRODUCT_LIST
export const productList = (params, callback) => doAction(PRODUCT_LIST, params, callback);
export const productListSuccess = (data) => doResponseAction(PRODUCT_LIST_SUCCESS, data);
export const productListFailure = (data) => doResponseAction(PRODUCT_LIST_FAILURE, data);

// PRODUCT_SEARCH
export const productSearch = (params, callback) => doAction(PRODUCT_SEARCH, params, callback);
export const productSearchSuccess = (data) => doResponseAction(PRODUCT_SEARCH_SUCCESS, data);
export const productSearchFailure = (data) => doResponseAction(PRODUCT_SEARCH_FAILURE, data);

// PRODUCT_UPDATE
export const productUpdate = (params, callback) => doAction(PRODUCT_UPDATE, params, callback);
export const productUpdateSuccess = (data) => doResponseAction(PRODUCT_UPDATE_SUCCESS, data);
export const productUpdateFailure = (data) => doResponseAction(PRODUCT_UPDATE_FAILURE, data);

// PRODUCT_SUBMIT
export const productSubmit = (params, callback) => doAction(PRODUCT_SUBMIT, params, callback);
export const productSubmitSuccess = (data) => doResponseAction(PRODUCT_SUBMIT_SUCCESS, data);
export const productSubmitFailure = (data) => doResponseAction(PRODUCT_SUBMIT_FAILURE, data);

//ENVIRONMENT_LIST LIST
export const environmentList = (params, callback) => doAction(ENVIRONMENT_LIST, params, callback);
export const environmentListSuccess = (data) => doResponseAction(ENVIRONMENT_LIST_SUCCESS, data);
export const environmentListFailure = (data) => doResponseAction(ENVIRONMENT_LIST_FAILURE, data);

//VENDOR LIST
export const vendorList = (params, callback) => doAction(VENDOR_LIST, params, callback);
export const vendorListSuccess = (data) => doResponseAction(VENDOR_LIST_SUCCESS, data);
export const vendorListFailure = (data) => doResponseAction(VENDOR_LIST_FAILURE, data);