import React, { useEffect, useState } from "react"
import MESSAGE from "../constant/String"
import { Actions } from "../redux/action"
import { useDispatch } from "react-redux"
import { toast } from "react-toastify"
import Loader from "./Loader"
import TableContent from "./TableContent"

const ProductPopup = ({ close, productDetails, isCompleted, handleProductDetails }) => {

    const [activeImageIndex, setActiveImageIndex] = useState(0)
    const [activeImageId, setActiveImageId] = useState()
    const [updateDetails, setUpdateDetails] = useState([])
    const [isTable, setIsTable] = useState([])
    const [imageStatus, setImageStatus] = useState({ imageStatusId: "", imageStatusText: "" })
    const [isLoading, setIsLoading] = useState(false)
    const [error, setError] = useState({ nutrition: "", ingredient: "", overallStatus: "" })
    const [zoomStyle, setZoomStyle] = useState(Array(productDetails?.product_images.length).fill(null));
    const dispatch = useDispatch();
    const [activeTab, setActiveTab] = useState(0)
    const [newNutritionData, setNewNutritionData] = useState([])
    const [keysWithArrays, setKeysWithArrays] = useState([])
    const [category, setCategory] = useState('')  //It is for add row and add column based on this in nutritions table


    //To set the image details for the index 0 of the image for intial scale
    useEffect(() => {
        const { product_images } = productDetails
        if (product_images?.length > 0) {
            setActiveImageId(product_images[0]?.id)
            if (isCompleted) {
                setImageStatus({ ...imageStatus, imageStatusId: "" })
            }
            handleTable(activeImageIndex, product_images[0]?.is_table)
            const data = {
                id: product_images[0]?.id,
                ingredients_info: product_images[0]?.ingredients_info,
                allergy_info: product_images[0]?.allergy_info,
                contains_info: product_images[0]?.contains_info,
                nutrition_info: [],
                sku: product_images[0]?.sku,
                product: product_images[0]?.product,
                is_ingredients: product_images[0]?.is_ingredients ?? false,
                is_nutrition: product_images[0]?.is_nutrition ?? false,
                is_allergy: product_images[0]?.is_allergy ?? false,
                is_contains: product_images[0]?.is_contains ?? false,
                is_allergy_approve: isCompleted ? product_images[0]?.is_allergy : false,
                is_contains_approve: isCompleted ? product_images[0]?.is_contains : false,
                is_ingredients_approve: isCompleted ? product_images[0]?.is_ingredients : false,
                is_nutrition_approve: isCompleted ? product_images[0]?.is_nutrition : false,
                is_table: product_images[0]?.is_table,
                v2nutrition_info: product_images[0]?.v2nutrition_info && parseArray(product_images[0], product_images[0]?.id, 0),
            }
            setUpdateDetails([data])
        }
    }, [])

    // Extract keys with associated arrays for category selection against nutritions
    const exctractKey = (jsonData) => {
        const keysWithArraysData = Object.keys(jsonData).filter(
            key => Array.isArray(jsonData[key])
        );
        setKeysWithArrays(keysWithArraysData)
    }


    //It is to formet the data to object to key value data for each object value.
    const formatNutritionData = (data, id, activeIndex) => {
        const formattedData = {};
        for (const key in data) {
            if (Array.isArray(data[key])) {
                formattedData[key] = data[key].map((item) => {
                    const label = item.label ?? "";
                    const unitValue = item.unitValue ?? "";
                    const unit = item.unit ?? "";
                    const value = item.value || 0;

                    return [label, `${unitValue}${unit}`, parseFloat(value)];
                });
            } else {
                formattedData[key] = [data[key]]
            }
        }
        handleNutritionData(formattedData, id, activeIndex)
    }

    // handle the nutritions data convert the object to array of array for table formet
    const handleNutritionData = (data, imageId, activeIndex) => {
        const resultArray = [];
        for (const key in data) {
            const value = data[key];
            if (Array.isArray(value[0])) {
                resultArray.push([key]);
                value.forEach(arr => resultArray.push([...arr]));
            } else {
                resultArray.push([key, ...value]);
            }
        }
        let arrayData = [...newNutritionData]
        arrayData[activeIndex] = { id: imageId, nutrition: resultArray }
        setNewNutritionData(arrayData)
    }

    // This function to reconvert the array to the object form for api
    const convertArrayToObject = (array) => {
        const resultObject = {};
        let currentKey = null;

        array.forEach((item) => {
            if (item.length === 1) {
                currentKey = item[0];
                resultObject[currentKey] = [];
            } else if (currentKey) {
                const [label, unitValue, value] = item;
                resultObject[currentKey].push({
                    label,
                    unitValue,
                    value,
                });
            } else {
                const [key, value] = item;
                resultObject[key] = value;
            }
        });
        return resultObject;
    };

    // This function is to handle the isTable functionality  by set the state for table.
    const handleTable = (index, value) => {
        const updatedIsTable = [...isTable];
        updatedIsTable[index] = value;
        setIsTable(updatedIsTable)
    }

    const parseArray = (data, id, activeIndexData) => {
        var nutrition = data?.v2nutrition_info === "" ? '[]' : data?.v2nutrition_info
        nutrition = nutrition?.replace(/'/g, '"') //replacing all ' with 
        nutrition = JSON.parse(nutrition)
        exctractKey(nutrition[0] ?? nutrition)
        formatNutritionData((nutrition[0] ?? nutrition), id, activeIndexData)
    }

    const parseArrayToString = (data) => {
        const stringifiedObject = JSON.stringify(data)
        return stringifiedObject
    }

    //callack to show the toaster for update api.
    const updateCallback = (respone) => {
        setIsLoading(false)
        if (respone?.status_code !== 0) {
            toast.error(respone?.message)
        } else {
            toast.success(respone?.message)
            handleProductDetails()
        }
    }

    //Function to call the update api affect the error state and isLoading state
    const handleUpdate = () => {
        const specificTableValue = newNutritionData?.find((item) => item?.id == activeImageId)
        const specificUpdatedDetails = updateDetails?.find((item) => item?.id == activeImageId)
        const isTableStatus = isTable[activeImageIndex]
        const v2nutrition_info = convertArrayToObject(specificTableValue?.nutrition ?? [])
        const stringV2nutrition_info = parseArrayToString(v2nutrition_info)

        if (isTableStatus ? !specificTableValue : ((specificUpdatedDetails?.is_nutrition ?? false) && !specificUpdatedDetails?.nutrition_info)) {
            setError({ ...error, nutrition: "Please enter the nutrition details" })
        } else if ((specificUpdatedDetails?.ingredients_info ?? false) && !specificUpdatedDetails?.ingredients_info) {
            setError({ ...error, ingredient: "Please enter the ingredient details" })
        } else {
            let params = {
                product_image_id: specificTableValue?.id??specificUpdatedDetails?.id,
                nutrition_info: specificTableValue?.nutrition,
                ingredients_info: specificUpdatedDetails?.ingredients_info,
                allergy_info: specificUpdatedDetails?.allergy_info ?? "",
                contains_info: specificUpdatedDetails?.contains_info ?? "",
                is_nutrition: specificUpdatedDetails?.is_nutrition ?? false,
                is_ingredient: specificUpdatedDetails?.is_ingredients ?? false,
                is_allergy: specificUpdatedDetails?.is_allergy ?? false,
                is_contains: specificUpdatedDetails?.is_contains ?? false,
                is_table: isTable[activeImageIndex],
                v2nutrition_info: stringV2nutrition_info
            }
            setIsLoading(true)
            dispatch(Actions.productUpdate(params, updateCallback))
        }
    }

    const handleNext = () => {
        setActiveTab(prev => {
            return prev + 1;
        });
    }

    //handle submit 
    const handleSubmit = () => {
        const isNutritionApproved = !(updateDetails?.find(item => item?.is_nutrition_approve === true)) ?? true
        const isIngredientApproved = !(updateDetails?.find(item => item?.is_ingredients_approve === true)) ?? true
        const isAllergensApproved = !(updateDetails?.find(item => item?.is_allergy_approve === true)) ?? true
        const isContainsApproved = !(updateDetails?.find(item => item?.is_contains_approve === true)) ?? true

        const callback = (res) => {
            setIsLoading(false)
            if (res?.status_code !== 0) {
                toast.error(res?.message)
            } else {
                toast.success(res?.message)
            }
        }
        if (!imageStatus?.imageStatusId) {
            setError({ ...error, overallStatus: "Please select overall image status" })
        } else if (isIngredientApproved && (updateDetails[activeImageIndex]?.is_ingredients)) {
            toast.error("Please enable the Ingredient approve")
        } else if (isNutritionApproved && (updateDetails[activeImageIndex]?.is_nutrition)) {
            toast.error("Please enable the Nutrition approve")
        } else if (isAllergensApproved && (updateDetails[activeImageIndex]?.is_allergy)) {
            toast.error("Please enable the Allergens approve")
        } else if (isContainsApproved && (updateDetails[activeImageIndex]?.is_contains)) {
            toast.error("Please enable the Contains approve")
        }
        else {
            let specificTableValue = newNutritionData?.find((item) => item?.id == activeImageId)
            const v2nutrition_info = convertArrayToObject(specificTableValue?.nutrition ?? [])
            const stringV2nutrition_info = parseArrayToString(v2nutrition_info)

            let params = {
                product_id: updateDetails?.find(item => (item?.is_nutrition_approve === true || item?.is_allergy_approve === true || item?.is_contains_approve === true || item?.is_ingredients_approve === true))?.product,
                nutrition_info: [],
                ingredient_info: updateDetails?.find(item => item?.is_ingredients_approve === true)?.ingredients_info ?? '',
                allergy_info: updateDetails?.find(item => item?.is_allergy_approve === true)?.allergy_info ?? '',
                contains_info: updateDetails?.find(item => item?.is_contains_approve === true)?.contains_info ?? '',
                is_nutrition: true,
                is_ingredient: true,
                is_allergy: true,
                is_contains: true,
                status: imageStatus?.imageStatusId,
                message: imageStatus?.imageStatusText,
                is_table: isTable[activeImageIndex],
                v2nutrition_info: stringV2nutrition_info
            }
            setIsLoading(true)
            dispatch(Actions.productSubmit(params, callback))
        }
    }

    //Function to update the image which is active and the image data affected the activeImageIndex state and updateDetails
    const handleClick = (activeIndex, data) => {
        if (isTable[activeIndex] === undefined || isTable[activeIndex] === '') {  //condition for if the state already has no value only we can trgger the handleTable method for onclick the image.
            handleTable(activeIndex, data?.is_table)
        }
        setActiveImageIndex(activeIndex)
        setActiveImageId(data.id)
        setActiveTab(0)
        setCategory("")
        setImageStatus({ ...imageStatus, imageStatusId: "" })
        setError({ nutrition: "", ingredient: "", overallStatus: "" })
        let imageData = {
            id: data.id,
            ingredients_info: data?.ingredients_info,
            nutrition_info: data?.nutrition_info,
            v2nutrition_info: data?.v2nutrition_info,
            sku: data?.sku,
            product: data?.product,
            is_ingredients: data?.is_ingredients,
            is_nutrition: data?.is_nutrition,
            is_allergy_approve: isCompleted ? data?.is_allergy : false,
            is_contains_approve: isCompleted ? data?.is_contains : false,
            is_ingredients_approve: isCompleted ? data?.is_ingredients : false,
            is_nutrition_approve: isCompleted ? data?.is_nutrition : false,
            is_table: data?.is_table
        }
        if (!newNutritionData[activeIndex]) {
            parseArray(imageData, data.id, activeIndex)
        }
    }

    //Function to update the overall image status dropdown for submit api value affected the updateDetials state
    const handleImageStatus = (e) => {
        if (!isCompleted) {
            if (!e.target.value) {
                setError({ ...error, overallStatus: "Please select overall image status" })
                setImageStatus({ imageStatusId: e.target.value, imageStatusText: e.target.options[e.target.selectedIndex].text })
            } else {
                setError({ ...error, overallStatus: "" })
                setImageStatus({ imageStatusId: e.target.value, imageStatusText: e.target.options[e.target.selectedIndex].text })
            }
        }
    }

    //Function for the textarea changes nutrition and ingredient for each image affected the updateDetials state
    const handleChange = (e, flag) => {
        if (flag === 1) {
            const data = [...updateDetails];
            data[activeImageIndex] = { ...updateDetails[activeImageIndex], nutrition_info: e.target.value };
            setUpdateDetails(data)
        }
        else if (flag === 2) {
            const data = [...updateDetails];
            data[activeImageIndex] = { ...updateDetails[activeImageIndex], allergy_info: e.target.value };
            setUpdateDetails(data)
        }
        else if (flag === 3) {
            const data = [...updateDetails];
            data[activeImageIndex] = { ...updateDetails[activeImageIndex], contains_info: e.target.value };
            setUpdateDetails(data)
        }
        else {
            const data = [...updateDetails];
            data[activeImageIndex] = { ...updateDetails[activeImageIndex], ingredients_info: e.target.value };
            setUpdateDetails(data)
        }
    }

    //Function to update the nutrition and ingredient for each image checkbox value affected the updateDetails state
    const handleCheck = (e, flag) => {
        if (!isCompleted) {
            if (flag === 1) {
                const data = [...updateDetails];
                data[activeImageIndex] = { ...updateDetails[activeImageIndex], is_nutrition: e.target.checked };
                setUpdateDetails(data)
            } else if (flag === 2) {
                const data = [...updateDetails];
                data[activeImageIndex] = { ...updateDetails[activeImageIndex], is_allergy: e.target.checked };
                setUpdateDetails(data)
            } else if (flag === 3) {
                const data = [...updateDetails];
                data[activeImageIndex] = { ...updateDetails[activeImageIndex], is_contains: e.target.checked };
                setUpdateDetails(data)
            }
            else {
                const data = [...updateDetails];
                data[activeImageIndex] = { ...updateDetails[activeImageIndex], is_ingredients: e.target.checked };
                setUpdateDetails(data)
            }
        }
    }

    //Function is used to provide the correct nutrition and ingredient value for submit api affect the updateDetails state
    const handleApprove = (e, flag) => {
        if (!isCompleted) {
            if (flag === 1) {
                const data = [...updateDetails];
                data[activeImageIndex] = { ...updateDetails[activeImageIndex], is_nutrition_approve: e.target.checked, product: updateDetails[0]?.product };
                data?.forEach((image, index) => {
                    if (index != activeImageIndex) {
                        data[index] = { ...image, is_nutrition_approve: false };
                    }
                })
                setUpdateDetails(data)
            }
            else if (flag === 2) {
                const data = [...updateDetails];
                data[activeImageIndex] = { ...updateDetails[activeImageIndex], is_allergy_approve: e.target.checked, product: updateDetails[0]?.product };
                data?.forEach((image, index) => {
                    if (index != activeImageIndex) {
                        data[index] = { ...image, is_allergy_approve: false };
                    }
                })
                setUpdateDetails(data)
            }
            else if (flag === 3) {
                const data = [...updateDetails];
                data[activeImageIndex] = { ...updateDetails[activeImageIndex], is_contains_approve: e.target.checked, product: updateDetails[0]?.product };
                data?.forEach((image, index) => {
                    if (index != activeImageIndex) {
                        data[index] = { ...image, is_contains_approve: false };
                    }
                })
                setUpdateDetails(data)
            }
            else {
                const data = [...updateDetails];
                data[activeImageIndex] = { ...updateDetails[activeImageIndex], is_ingredients_approve: e.target.checked, product: updateDetails[0]?.product };
                data?.forEach((image, index) => {
                    if (index != activeImageIndex) {
                        data[index] = { ...image, is_ingredients_approve: false };
                    }
                })
                setUpdateDetails(data)
            }
        }
    }

    //function used to zoom the image on hover 
    const handleMouseMove = (e, index) => {
        const { left, top, width, height } = e.target.getBoundingClientRect();
        const mouseX = e.clientX - left;
        const mouseY = e.clientY - top;
        const imageX = (mouseX / width) * 100;
        const imageY = (mouseY / height) * 100;
        const updatedZoomStyles = zoomStyle?.map((style, i) => {
            if (i === index) {
                return {
                    transform: `scale(2)`,
                    transformOrigin: `${imageX}% ${imageY}%`,
                    transition: 'transform 0.6s',
                };
            } else {
                return null;
            }
        });

        setZoomStyle(updatedZoomStyles);
    };

    const handleActiveTab = () => {
        setActiveTab(prev => {
            if (prev != 0) {
                return prev - 1;
            }
        })
    }

    //function used to remove zoom from the image on mouse leave 
    const handleMouseLeave = () => {
        const updatedZoomStyles = zoomStyle?.map(() => null);
        setZoomStyle(updatedZoomStyles);
    };
    let isStatusChecked = (!(updateDetails[activeImageIndex]?.is_ingredients) ?? true) && (!(updateDetails[activeImageIndex]?.is_nutrition) ?? true) && (!(updateDetails[activeImageIndex]?.is_allergy) ?? true) && (!(updateDetails[activeImageIndex]?.is_contains) ?? true)
    return (
        <React.Fragment>
            {
                isLoading && <Loader />
            }
            {
                <>
                    <div className="modal">
                        <div className="modal-dialog productDialogPopup">
                            <div className={`modal-content productPopup ${productDetails?.product_images[activeImageIndex]?.is_table ? "modelNutrition" : ""}`}>
                                <div className="close_part" onClick={() => close()}>
                                    <i className="fa-solid fa-xmark"></i>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6 productImageView">
                                            <div className="my-1">
                                                Total Images:{productDetails?.product_images?.length}
                                            </div>
                                            {
                                                productDetails?.product_images?.length > 0 && productDetails?.product_images?.map((item, index) => {
                                                    return (
                                                        <div className="row imageCardRow my-2">
                                                            <div id="imageContainer" className={activeImageIndex === index ? "card imageCard shadow-sm imageActive" : "card imageCard shadow-sm"} onMouseMove={(e) => handleMouseMove(e, index)} onMouseLeave={handleMouseLeave} onClick={() => handleClick(index, item)}>
                                                                <img className="d-block mx-auto" src={item?.image_url} alt="logo" style={zoomStyle[index]} id="productImage" />
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div className="col-6">
                                            {
                                                activeTab === 0 ? (<div className="row mx-1">
                                                    <div className={`card ${(!error?.nutrition && !error?.ingredient) && 'my-2'}`}>
                                                        <div className="popupHeader text-start my-1 d-flex justify-content-between">
                                                            {MESSAGE.NUTRITION}:
                                                            {
                                                                (!isCompleted || (updateDetails[activeImageIndex]?.is_nutrition_approve)) &&
                                                                <div className="d-flex justify-content-between">
                                                                    <div>
                                                                        <input checked={updateDetails[activeImageIndex]?.is_nutrition_approve} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" onClick={(e) => handleApprove(e, 1)} /> <span className="nutritionApprove">Approve</span>
                                                                    </div>
                                                                </div>
                                                            }

                                                        </div>
                                                        {
                                                            <TableContent category={category} setCategory={setCategory} imageIndex={activeImageIndex} imageId={activeImageId} setNewNutritionData={setNewNutritionData} newNutritionData={newNutritionData} keysWithArrays={keysWithArrays} />
                                                        }
                                                    </div>
                                                </div>)
                                                    :
                                                    activeTab === 1 ?
                                                        (
                                                            <>
                                                                <div className="row mx-1">
                                                                    <div className={`card ${(!error?.nutrition && !error?.ingredient) && 'my-2'}`}>
                                                                        <div className="popupHeader text-start my-1 d-flex justify-content-between">
                                                                            {MESSAGE.ALLERGENS}:
                                                                            {
                                                                                (!isCompleted || (updateDetails[activeImageIndex]?.is_allergy_approve)) &&
                                                                                <div>
                                                                                    <input checked={updateDetails[activeImageIndex]?.is_allergy_approve} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" onClick={(e) => handleApprove(e, 2)} /> <span className="nutritionApprove">Approve</span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="text-start">
                                                                            <textarea name="" id="" cols="70" rows="5" className="no-resize" value={updateDetails[activeImageIndex]?.allergy_info} onChange={(e) => handleChange(e, 2)}></textarea>
                                                                            {error?.ingredient && <p className="errorMessage">{error?.ingredient}</p>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row mx-1">
                                                                    <div className={`card ${(!error?.nutrition && !error?.ingredient) && 'my-2'}`}>
                                                                        <div className="popupHeader text-start my-1 d-flex justify-content-between">
                                                                            {MESSAGE.CONTAINS}:
                                                                            {
                                                                                (!isCompleted || (updateDetails[activeImageIndex]?.is_contains_approve)) &&
                                                                                <div>
                                                                                    <input checked={updateDetails[activeImageIndex]?.is_contains_approve} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" onClick={(e) => handleApprove(e, 3)} /> <span className="nutritionApprove">Approve</span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="text-start">
                                                                            <textarea name="" id="" cols="70" rows="5" className="no-resize" value={updateDetails[activeImageIndex]?.contains_info} onChange={(e) => handleChange(e, 3)}></textarea>
                                                                            {error?.ingredient && <p className="errorMessage">{error?.ingredient}</p>}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </>
                                                        ) :
                                                        (
                                                            <>
                                                                <div className="row mx-1">
                                                                    <div className={`card ${(!error?.nutrition && !error?.ingredient) && 'my-2'}`}>
                                                                        <div className="popupHeader text-start my-1 d-flex justify-content-between">
                                                                            {MESSAGE.INGREDIENT}:
                                                                            {
                                                                                (!isCompleted || (updateDetails[activeImageIndex]?.is_ingredients_approve)) &&
                                                                                <div>
                                                                                    <input checked={updateDetails[activeImageIndex]?.is_ingredients_approve} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" onClick={(e) => handleApprove(e, 4)} /> <span className="nutritionApprove">Approve</span>
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                        <div className="text-start">
                                                                            <textarea name="" id="" cols="70" rows="5" className="no-resize" value={updateDetails[activeImageIndex]?.ingredients_info} onChange={(e) => handleChange(e, 4)}></textarea>
                                                                            {error?.ingredient && <p className="errorMessage">{error?.ingredient}</p>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col text-start px-4">
                                                                        <h5>Over All Image Status</h5>
                                                                        <div className="pt-lg-0 pt-3">
                                                                            {
                                                                                isCompleted ?
                                                                                    <p>{productDetails?.message}</p>
                                                                                    :
                                                                                    <select className="form-select pe-5 fs-14" value={imageStatus?.imageStatusId} onChange={handleImageStatus}>
                                                                                        <option selected value="">Please Select Image Status</option>
                                                                                        <option value={0}>Image Quality Good</option>
                                                                                        <option value={1}>Image Quality Poor</option>
                                                                                        <option value={2}>Nutritional Details Clear.Ingredient Not Visible</option>
                                                                                        <option value={3}>Ingredients Details Clear.Nutritional Info Not Visible</option>
                                                                                        <option value={4}>Image Contains Only Nutrition Info</option>
                                                                                        <option value={5}>Image Contains Only Ingredients Info</option>
                                                                                        <option value={6}>Image OCR Process Failed. Internal Server Failure</option>
                                                                                    </select>
                                                                            }

                                                                        </div>
                                                                        {
                                                                            error?.overallStatus && <p className="errorMessage">{error?.overallStatus}</p>
                                                                        }
                                                                    </div>
                                                                    <div className="col text-start px-4">
                                                                        <div className="my-3">
                                                                            <input checked={updateDetails[activeImageIndex]?.is_nutrition ?? false} className="form-check-input " type="checkbox" value="" id="flexCheckChecked" onClick={(e) => handleCheck(e, 1)} /> <span>{MESSAGE.NUTRITION}</span>
                                                                        </div>
                                                                        <div className="my-3">
                                                                            <input checked={updateDetails[activeImageIndex]?.is_allergy ?? false} className="form-check-input " type="checkbox" value="" id="flexCheckChecked" onClick={(e) => handleCheck(e, 2)} /> <span>{MESSAGE.ALLERGENS}</span>
                                                                        </div> <div className="my-3">
                                                                            <input checked={updateDetails[activeImageIndex]?.is_contains ?? false} className="form-check-input " type="checkbox" value="" id="flexCheckChecked" onClick={(e) => handleCheck(e, 3)} /> <span>{MESSAGE.CONTAINS}</span>
                                                                        </div>
                                                                        <div className="my-1">
                                                                            <input checked={updateDetails[activeImageIndex]?.is_ingredients ?? false} className="form-check-input" type="checkbox" value="" id="flexCheckChecked" onClick={(e) => handleCheck(e, 4)} /> <span>{MESSAGE.INGREDIENT}</span>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        !isCompleted &&
                                                                        <div className="col popupUpdate">
                                                                            <button className="btn btn-primary" onClick={handleUpdate}>{MESSAGE.UPDATE}</button>
                                                                        </div>
                                                                    }

                                                                </div>
                                                            </>
                                                        )
                                            }
                                        </div>
                                    </div>
                                </div>
                                {
                                    activeTab == 2 ? <div className="modal-footer">
                                        <button type="button" className="btn btn-primary"
                                            onClick={() => handleActiveTab()}>
                                            {MESSAGE.PREVIOUS}
                                        </button>
                                        {
                                            !isCompleted &&
                                            <button type="button" className="btn btn-primary"
                                                disabled={
                                                    !(Object.keys(error)?.every(key => error[key] === "")
                                                        && imageStatus?.imageStatusId !== '') ||
                                                    isStatusChecked
                                                }
                                                onClick={() => handleSubmit()}>
                                                {MESSAGE.SUBMIT}
                                            </button>
                                        }

                                    </div>
                                        :
                                        activeTab == 0 ?
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary"
                                                    onClick={() => handleNext()}>
                                                    {MESSAGE.NEXT}
                                                </button>
                                            </div>
                                            :
                                            <div className="modal-footer">
                                                <button type="button" className="btn btn-primary"
                                                    onClick={() => handleActiveTab()}>
                                                    {MESSAGE.PREVIOUS}
                                                </button>
                                                <button type="button" className="btn btn-primary"
                                                    onClick={() => handleNext()}>
                                                    {MESSAGE.NEXT}
                                                </button>
                                            </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="modal-backdrop show loaderBackGround"></div>
                </>

            }

        </React.Fragment>
    )
}
export default ProductPopup