import empty from '../asset/img/Empty.png'
export const DataNotFound = ({ Error }) => {
    return (
        <>
            <div className="text-center">
                <img src={empty} alt="Nol Results" className='mt-5' style={{ width: "200px", height: "200px" }} /><br /><br />
                <h4 className="font16 mb-2 fw-bold">
                    {Error}
                </h4>
            </div>
        </>
    )
}