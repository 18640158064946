import { takeLatest, all, put } from 'redux-saga/effects';
import { login, logout } from '../../service';
import { Actions } from '../action/index';
import { LOGIN_USER, LOGOUT } from '../action/loginAction';
import END_POINTS from '../../service/EndPoint';

export function* loginUser(param) {
  const { params, callback } = param

  try {
    const response = yield login(END_POINTS.LOGIN, params)
    yield sessionStorage.setItem('token', JSON.stringify(response.token));
    yield sessionStorage.setItem('user_id', JSON.stringify(response.user_id));
    yield put(Actions.loginUserSuccess(response))
    callback(response)

  } catch (error) {
    callback({ error })
    yield put(Actions.loginUserFailure(error))
  }
}

export function* logoutUser(param) {
  const { params, callback } = param

  try {
    const response = yield logout()
    yield put(Actions.logOutSuccess(response))
    callback(response)

  } catch (error) {
    callback({ error })
    yield put(Actions.logOutFailure(error))
  }
}


export default function* loginWatcher() {
  yield all([
    takeLatest(LOGIN_USER, loginUser),
    takeLatest(LOGOUT, logoutUser),
  ]);
}
