const TableContent = ({ category,setCategory, imageIndex, imageId, newNutritionData, setNewNutritionData, keysWithArrays }) => {

    //handle the onchnage value in table based on the row, column and over all imageIndex on the table affected the tableData state
    const handleNewValueChange = (rowIndex, columnIndex, event) => {
        let updatedData = newNutritionData?.find((item) => item?.id == imageId)?.nutrition;
        updatedData[rowIndex][columnIndex] = event.target.value;
        const checkData = [...newNutritionData]
        checkData[imageIndex] = { ...checkData[imageIndex], nutrition: updatedData }
        setNewNutritionData(checkData)
    };

    //handle the add row based on the selected key by the dropdown.
    const addRow = () => {
        const array = newNutritionData?.find((item) => item?.id == imageId)?.nutrition;
        const maxLengthOfNutrition = Math.max(...array?.map((arr) => arr.length));
        const newRow = Array(maxLengthOfNutrition)?.fill('');
        const keyIndex = array.findIndex((item) => item[0] === category);
        if (keyIndex !== -1) {
            const newDataRow = [...newNutritionData];
            const i = newDataRow.findIndex((o) => o?.id === imageId);
            newDataRow[i] = {
                ...newDataRow[i],
                nutrition: [
                    ...newDataRow[i]?.nutrition.slice(0, keyIndex + 1),
                    newRow,
                    ...newDataRow[i]?.nutrition.slice(keyIndex + 1),
                ],
            };
            setNewNutritionData(newDataRow);
        }
    };

    //handle the add column based on the selected key by the dropdown.
    const addColumns = () => {
        const newDataColumn = [...newNutritionData];
        if (newDataColumn[imageIndex]?.nutrition) {
            let foundPreServing = false;
            newDataColumn[imageIndex].nutrition = newDataColumn[imageIndex].nutrition.map(row => {
                if (row.includes(category)) {
                    foundPreServing = true;
                } else if (foundPreServing && row.length > 1) {
                    return [...row, ''];
                } else if (row.length === 1) {
                    foundPreServing = false;
                    return [...row]
                }
                return row;
            });
            if (foundPreServing) {
                newDataColumn[imageIndex].nutrition.push(['']);
            }
            setNewNutritionData(newDataColumn);
        }
    };

    //handle the delete row by splicing the table nutrition by row imageIndex and overall imageIndex of table affected the tableData state
    const deleteRows = rowIndex => {
        const i = newNutritionData.findIndex(o => o?.id === imageId)
        const updatedData = [...newNutritionData[i]?.nutrition];
        updatedData?.splice(rowIndex, 1);
        const deleteRow = [...newNutritionData]
        deleteRow[i] = { ...deleteRow[i], nutrition: updatedData }
        setNewNutritionData(deleteRow)
    };

    //handle category function to set the data for the category
    const handleCategory = (event) => {
        setCategory(event.target.value)
    }

    //Just to get the nutrition based on the image id or retrun empty array
    let updateTableData = newNutritionData?.find((item) => item?.id == imageId)?.nutrition ?? []

    return (
        <div className="overallTable">
            <div className="nutritionTable">
                <table>
                    <tbody>
                        {updateTableData?.map((row, rowIndex) => (
                            <tr key={rowIndex}>
                                {row?.map((cell, columnIndex) => (
                                    <td key={columnIndex}>
                                        <input
                                            type="text"
                                            value={cell}
                                            onChange={(event) => handleNewValueChange(rowIndex, columnIndex, event)}
                                        />
                                    </td>
                                ))}
                                {
                                    row?.length !== 1 &&
                                    <td >
                                        <i class="fa-solid fa-trash mx-2" onClick={() => deleteRows(rowIndex)}></i>
                                    </td>
                                }

                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div className="my-2 addDiv">
                <select value={category} onChange={handleCategory}>
                    <option selected value="">Please select the category</option>
                    {
                        keysWithArrays?.map((category, index) => {
                            return <option value={category} key={index}>{category}</option>
                        })
                    }
                </select>
                <button className="btn btn-primary mx-2" onClick={() => addRow('perServing')} disabled={!category}>Add Row</button>
                {/* <button className="btn btn-primary" onClick={() => addColumns()} disabled={!category}>Add Column</button> */}
            </div>
        </div>
    )
}

export default TableContent