import Pagination from 'react-js-pagination';
export const PaginationSession = ({ onChangeHandle, currentPage, sessionCount }) => {
    let count = parseInt(sessionCount)
    return (
        <>
            <div className="mt-3 d-flex justify-content-center">
                <Pagination
                    activePage={currentPage}
                    itemsCountPerPage={10}
                    totalItemsCount={count * 10}
                    pageRangeDisplayed={5}
                    onChange={onChangeHandle}
                    activeLinkClass='active'
                    itemClass='page-item'
                    linkClass="page-link"
                    prevPageText='prev'
                    nextPageText='next'
                />
            </div>
        </>
    )
}